.allinfluencer{
    padding-top: 50px;
    .main-heading {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .mainheadingbtns {
          display: flex;
          align-items: flex-start;
          gap: 17px;
    
          .searchinputt {
            position: relative;
            min-width: 345px;
            width: 100%;
    
            .searchimg {
              position: absolute;
              top: 15px;
              right: 15px;
            }
    
            input {
              border: 1px solid #FFF;
              background: #000;
              color: #FFF;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              outline: none;
              background: transparent;
              width: 100%;
              padding: 12px 15px;
              outline: none;
    
              &::placeholder {
                color: rgba(255, 255, 255, 0.60);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
              }
            }
          }
    
          .btn-influencer {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 2px 2px 0px 0px #FFF;
            display: flex;
            padding: 15px;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            white-space: nowrap;
          }
        }
    
        h6 {
          color: #fff;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 109.091% */
          letter-spacing: 0.44px;
        }
    
        .custom-option-field {
          max-width: 574px;
          width: 100%;
        }
      }
}

@media (max-width:1140px){
    .allinfluencer .main-heading{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
    }
    .allinfluencer .main-heading .mainheadingbtns{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }
    .allinfluencer .main-heading .mainheadingbtns .searchinputt{
        min-width: 100%;
    }
    .searchdropdown{
        max-width: 100%;
    }
    .allinfluencer .main-heading .mainheadingbtns .btn-influencer{
        width: 100%;
    }
}