.influencerstatsdetail {

    .statsheading {
        color: #FFF;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        margin-bottom: 32px;
    }


    .influencerstatmainfour {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .influencerstatmain {
        display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        margin-bottom: 30px;

        .innerinfluencerstat {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 3px 3px 0px 0px #FFF;
            padding: 25px;

            .influencerstathead {
                color: #FFF;
                opacity: 0.6;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-top: 20px;
                margin-bottom: 10px;
            }

            .influencerpara {
                color: #FFF;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.22px;
            }
        }
    }
}

.detailbox {
    border: 1px solid #FFF;
    background: #000;
    display: flex;
    padding: 30px;
    align-items: flex-start;
    gap: 30px 10px;
    margin-bottom: 30px;
    flex-wrap: wrap;

    .widthset {
        width: 50% !important;
    }

    .innerdetailbox {
        width: 32%;

        .detailhead {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 12px;
            opacity: 0.6;
        }

        .detailpara {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            max-width: 250px;
            width: 100%;
            word-break: break-all;
        }
    }
}

.exportbtn {
    margin-left: auto;
}

@media (max-width:991px) {
    .influencerstatsdetail .influencerstatmain {
        grid-template-columns: 1fr 1fr;
    }

    .influencerstatsdetail .influencerstatmain .innerinfluencerstat .influencerstathead {
        font-size: 12px;
    }

    .influencerstatsdetail .influencerstatmain .innerinfluencerstat .influencerpara {
        font-size: 16px;
    }

    .exportbtn {
        width: 100%;
    }
}

@media (max-width:600px) {
    .detailbox {
        flex-wrap: wrap;
        gap: 30px 0px;
    }

    .detailbox .innerdetailbox {
        width: 50%;
    }

    .detailbox .innerdetailbox .detailpara {
        font-size: 14px;
        word-break: break-all;
        max-width: 135px;
        width: 100%;
    }
}