.dashboard {
  padding: 60px 0;
  padding-top: 44px !important;

  .upper-twice{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  .footer-contenttt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    // border-top: 1px solid #F2F2F2;
    background-color: transparent;
    border: 1px solid #FFF;

    background: #000;
    margin-top: 20px;
    backdrop-filter: blur(3px);

    .left-f {
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #A0A1A1;
      }
    }

    .right-f {
      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-item {
        background-color: transparent !important;
        border: none !important;
      }

      .page-link {
        border: none;
        color: #A2A4A6 !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 15px */
        background-color: #000;
        border-radius: 0 !important;

        &.active {
          background: #fff;
          color: #000;
        }
      }

      .page-item.active .page-link {
        background: #fff;
        color: #000 !important;

        &:hover {
          background-color: #fff !important;
          color: #000 !important;
        }
      }

      .page-item:first-child .page-link {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #A0A1A1;
      }
    }
  }

  .chartsmain {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 30px;

    .innerchart {
      width: 100%;

      .charttop {
        padding-bottom: 4px;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        gap: 10px;

        .chartheading {
          color: #FFF;
          font-size: 15.295px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
        }

        .dropdown {
          .btn {
            padding: 0px;
            margin: 0px;
            background: transparent;
            border: none;
            outline: none;
            color: #FFF;
            font-size: 15.295px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 18.354px */
            gap: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .dropdown-menu {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 4px 4px 0px 0px #FFF;
            border-radius: 0px;
            padding: 0px;
            margin: 0px;

            .drop {
              color: #FFF;
              padding: 12px 15px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              border-bottom: 1px solid #F3F3F3;
              border-radius: 0px;
              cursor: pointer;
            }
          }
        }
      }

      .mainbigchart {
        width: 100%;
        border: 0.956px solid #FFF;
        background: #000;
        box-shadow: 2.86775px 2.86775px 0px 0px #FFF;
        padding: 19px 19px 0px;

        .new-accordionchart {
          .upper-body {

            .inner-text {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 2px solid #191919;

              h6 {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
                width: 80%;
              }

              .centertext{
                display: flex;
                justify-content: center !important;
              }

              p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
                width: 30%;
                text-align: end;
              }
            }

            .inner-texted {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 2px solid #191919;

              h6 {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
                width: 80%;
              }

              .centertext{
                justify-content: center !important;
              }

              p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
                width: 30%;
                text-align: end;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;

                .greentext {
                  color: #28E664;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 100%;
                  text-transform: uppercase;
                }
                .redtext {
                  color: #F00500;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 100%;
                  text-transform: uppercase;
                }
              }
            }

            .progress {
              border-radius: 40px;
              background: #191919;
              height: 3px;
              margin: 18px 0px;

              .progress-bar {
                border-radius: 40px;
                background: #FFF;
              }
            }
          }

          .bottom-body {
            .accordion {
              background: transparent;
              border: none;
              border-radius: 0px;

              .accordion-item {
                border: none;
                border-radius: 0px;
                display: flex !important;
                justify-content: center;
                align-items: center;
                flex-direction: column-reverse;
                border-radius: 0;
                background: #000;

                .accordion-button:not(.collapsed) {
                  box-shadow: none !important;
                }

                .accordion-header {
                  background: transparent;
                  border: none;
                  border-radius: 0px;

                  .accordion-button {
                    background: transparent;
                    border: none;
                    border-radius: 0px;
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0.28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    &:focus {
                      box-shadow: none !important;
                    }

                    &::after {
                      display: none;
                    }

                    p {
                      color: #FFF;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 21px;
                      letter-spacing: 0.28px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }

                }

                .accordion-collapse {
                  width: 100%;

                  .accordion-body {
                    width: 100%;
                    padding: 0px;

                    .inner-texted {
                      display: flex;
                      align-items: flex-start;
                      justify-content: space-between;
                      width: 100%;

                      h6 {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        text-transform: uppercase;
                        width: 80%;
                      }

                      p {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        text-transform: uppercase;
                        width: 10%;
                        text-align: end;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      }
                    }

                    .progress {
                      border-radius: 40px;
                      background: #191919;
                      height: 3px;
                      margin: 18px 0px;

                      .progress-bar {
                        border-radius: 40px;
                        background: #FFF;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .influencerstats {
    .statsheading {
      color: #FFF;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.7px;
      text-transform: uppercase;
  
    }

    .groupstatss {
      grid-template-columns: 1fr 1fr 1fr !important;
    }

    .influencerstatmainfour {
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }

    .influencerstatmainfive {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
    }

    .influencerstatmain {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      margin-bottom: 20px;

      .innerinfluencerstat {
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 3px 3px 0px 0px #FFF;
        padding: 20px;

        .newcarddtext {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          margin-bottom: 12px;

          .headnumber {
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.22px;
          }

          .paranumber {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;
          }
        }

        .lowercardtext {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          .lowercardheadd {
            color: #999;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.16px;
          }

          .lowercardpaera {
            color: #999;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            // text-transform: uppercase;
          }
        }

        .smalldata {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          gap: 20px;
          margin-top: 15px;

          .smallinnerdata {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;

            .linedata {
              border-radius: 4px;
              background: #FFF;
              width: 3px;
              height: 14px;
              display: flex;
            }

            .smalldatapara {
              color: #FFF;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -0.14px;
            }

            .completedddpara {
              color: #B2B2B2;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -0.14px;
            }
          }
        }

        .influencerstathead {
          color: #FFF;
          opacity: 0.6;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        .influencerpara {
          color: #FFF;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          letter-spacing: -0.22px;
        }
      }
    }
  }

  .main-heading {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rightsidebtns {
      display: flex;
      gap: 12px;
      align-items: center;

      .editbutton {
        display: flex;
        padding: 13px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: 1px solid #FFF;
        background: #000;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }

      .delbutton {
        display: flex;
        padding: 13px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #FE0000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        border: 1px solid #FE0000;
        background: #000;
      }
    }

    .mainheadingbtns {
      display: flex;
      align-items: flex-start;
      gap: 17px;

      .searchinputt {
        position: relative;
        min-width: 345px;
        width: 100%;

        .searchimg {
          position: absolute;
          top: 15px;
          right: 15px;
        }

        input {
          border: 1px solid #FFF;
          background: #000;
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          outline: none;
          background: transparent;
          width: 100%;
          padding: 12px 15px;
          outline: none;

          &::placeholder {
            color: rgba(255, 255, 255, 0.60);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }
        }
      }

      .btn-influencer {
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 2px 2px 0px 0px #FFF;
        display: flex;
        padding: 15px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        white-space: nowrap;
      }
    }

    h6 {
      color: #fff;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 109.091% */
      letter-spacing: 0.44px;
    }

    .custom-option-field {
      max-width: 574px;
      width: 100%;
    }
  }
}

@media (max-width: 1300px) {
  .dashboard .main-heading {
    flex-direction: column;
    gap: 30px;
  }
}

@media (max-width: 1199px) {
  // .dashboard .influencerstats .influencerstatmain {
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  // }

  .dashboard .influencerstats .groupstatss {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media(max-width:1030px) {
  .dashboard .main-heading .mainheadingbtns {
    flex-direction: column;
    width: 100%;
  }

  .searchdropdown .btn {
    width: 100%;
  }

  .dashboard .main-heading .mainheadingbtns .btn-influencer {
    width: 100%;
  }

  .dashboard .searchdropdown {
    max-width: 100%;
  }

  .dashboard .main-heading .mainheadingbtns .searchinputt {
    min-width: 100%;
  }
}

@media (max-width: 991px) {
  .dashboard .influencerstats .influencerstatmainfive {
    grid-template-columns: 1fr 1fr !important;
  }

  .dashboard .main-heading {
    margin-top: 20px;
  }

  .dashboard .influencerstats .influencerstatmainfour {
    grid-template-columns: 1fr 1fr !important;
  }

  .dashboard .influencerstats .influencerstatmain .innerinfluencerstat .lowercardtext .lowercardpaera {
    font-size: 12px;
  }

  // .dashboard .influencerstats .influencerstatmain {
  //   grid-template-columns: 1fr 1fr !important;
  //   gap: 10px;
  // }

  .dashboard .influencerstats .groupstatss {
    grid-template-columns: 1fr 1fr !important;
    gap: 10px;
  }

  .dashboard .chartsmain {
    flex-direction: column;
  }

  .dashboard .chartsmain .innerchart {
    width: 100%;
  }

  .dashboard .influencerstats .statsheading {
    font-size: 22px;
  }

  .dashboard .influencerstats .influencerstatmain .innerinfluencerstat .influencerstathead {
    font-size: 12px;
  }

  .dashboard .influencerstats .influencerstatmain .innerinfluencerstat .influencerpara {
    font-size: 16px;
  }

  .setordercustom1mbl {
    order: 1;
    width: 100%;
    grid-column: span 2;
  }

  .setordercustom1mbl1 {
    order: 2;
    grid-column: span 2;
  }

  .setordercustom1mbl2 {
    order: 3;
  }

  .setordercustom1mbl3 {
    order: 4;
  }
}

@media (max-width: 600px) {
  .dashboard .main-heading .rightsidebtns {
    flex-direction: column;
    width: 100%;
  }

  .dashboard .main-heading .rightsidebtns .editbutton {
    width: 100%;
  }

  .dashboard .main-heading .rightsidebtns .delbutton {
    width: 100%;
  }

  .dashboard .main-heading .mainheadingbtns {
    flex-direction: column;
    width: 100%;
  }

  .nav-pills .nav-item {
    width: 33.3%;
  }

  .nav-pills .nav-item .nav-link {
    width: 100%;
    padding: 20px 0px;
  }

  .nav-pills {
    max-width: 100%;
    width: 100%;
  }

  .dashboard .influencerstats .influencerstatmain {
    grid-template-columns: 1fr 1fr;
  }

  .dashboard .influencerstats .influencerstatmainfour {
    grid-template-columns: 1fr 1fr !important;
  }

  .dashboard .main-heading .mainheadingbtns .btn-influencer {
    width: 100%;
  }

  .dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-text h6 {
    width: 50%;
  }

  .dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-text p {
    width: 25%;
  }

  .dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-texted h6 {
    width: 50%;
  }

  .dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-texted p {
    width: 25%;
  }

  .dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .bottom-body .accordion .accordion-item .accordion-collapse .accordion-body .inner-texted h6 {
    width: 50%;
  }

  .dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .bottom-body .accordion .accordion-item .accordion-collapse .accordion-body .inner-texted p {
    width: 25%;
  }
}


// new ui scss here..................................

.custom-tab-bar {
  border: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 331px;
  width: 100%;
  position: relative;
  height: 46px;

  a {
    padding: 6px 11px;
    color: #a2a4a6;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-align: center;
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background: #fff;
      color: #000;
      padding: 11px;
    }
  }
}

.cal {
  .rdrCalendarWrapper {
    position: absolute;
    top: 50px;
    z-index: 99;
    border: 1px solid #fff;
    background: #000;
    box-shadow: 4px 4px 0px 0px #fff;
    border-radius: 0;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }
}

.mainbuttonsss button {
  border: none !important;
  color: #f2f2f2 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  letter-spacing: -0.32px;
}

.custom-tab-bar button {
  border: none !important;
  color: #f2f2f2 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  letter-spacing: -0.32px;
  background-color: transparent;
  padding: 10px 16px;
}

.rdrDayNumber span {
  color: #f2f2f2 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  letter-spacing: -0.32px;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: #fff !important;
  border-radius: 0 !important;
  border: none !important;
  color: #000 !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
  color: #000 !important;
}

.dashboard .mainbuttonsss .gusvtsvtvtsvxs {
  display: none !important;
}

.dashboard .custom-tab-bar .gusvtsvtvtsvxs {
  display: none !important;
}

@media (max-width: 991px) {
  .custom-tab-bar {
    max-width: 100%;
  }

  .mainbuttonsss button {
    width: auto !important;
  }

  .custom-tab-bar button {
    width: auto !important;
  }

  .dashboard .bottom-cards .single-card img {
    display: none !important;
  }

  .dashboard .bottom-cards .single-card {
    height: 100% !important;
    min-height: 170px;
  }

  .dashboard .bottom-cards .single-card p {
    margin-top: 0;
  }

  .dashboard .bottom-cards .single-card h6 span {
    font-size: 12px !important;
  }

  .datatable .main-heading {
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mobile-spec-height {
    min-height: 199px !important;
  }

  .noofleaders-card {
    .bottom-text {
      flex-wrap: nowrap !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      transition: 0.3s linear;
    }

    .twice-textbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .showtext {
      height: 100%;
      opacity: 1;
    }

    .hidetext {
      opacity: 0;
      height: 0%;
    }

    .transform-img {
      transform: rotate(180deg);
    }
  }

  .dashboard .bottom-cards .single-card .arrow-down-icon {
    display: block !important;
  }

  .ordersetsm2 {
    order: 2;
  }

  .ordersetsm1 {
    order: 1;
  }

  .new-accordionmobile {
    display: block !important;
    border: 1px solid #fff;
    background: #000;

    .upper-body {
      padding: 22px 25px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;


      .inner-text {
        flex: 0 0 46%;

        .status {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          .statushead {
            color: #28E664;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.28px;
          }

          .datepara {
            color: #FFF;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.1px;
          }
        }

        h6 {
          color: #8e8e8e;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 8px;
        }

        p {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          word-break: break-all;

        }
      }
    }

    .bordernone {
      border: none !important;
    }

    .accordion-item {
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      border-radius: 0;
      background: #000;

      .accordion-header {
        width: 100%;
      }

      .accordion-button {
        border-top: 1px solid #fff;
        background: #000;
        border-radius: 0;
        padding: 14px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        box-shadow: none;

        &::after {
          display: none;
        }

        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.28px;

        &[aria-expanded="true"] {
          img {
            transform: rotate(180deg);
          }
        }
      }

      .accordion-body {
        background: #000;
        padding: 0px 25px;

        .inner-text {
          flex: 0 0 46%;
          margin-bottom: 20px;

          .status {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .statushead {
              color: #28E664;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0.28px;
            }

            .datepara {
              color: #FFF;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.1px;
            }
          }

          h6 {
            color: #8e8e8e;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 8px;
          }

          p {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            word-break: break-all;

          }
        }

        .inner-textt {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 20px 0;
          border-bottom: 1px solid #fafafa;

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          .status {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            flex-direction: column;

            .statushead {
              color: #28E664;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0.28px;
            }

            .datepara {
              color: #FFF;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              /* 200% */
              letter-spacing: 0.1px;
            }
          }

          p {
            color: #8e8e8e;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 0.24px;
          }

          h6 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            /* 150% */
            letter-spacing: 0.28px;
          }
        }
      }
    }

    .accordion-item .accordion-collapse {
      width: 100%;
    }
  }

  .dashboard .mainbuttonsss .gusvtsvtvtsvxs {
    display: block !important;
    border: 1px solid #fff !important;
    background: #000 !important;
    box-shadow: 4px 4px 0px 0px #fff;
    width: 100% !important;
  }

  .dashboard .custom-tab-bar .gusvtsvtvtsvxs {
    display: block !important;
    border: 1px solid #fff !important;
    background: #000 !important;
    box-shadow: 4px 4px 0px 0px #fff;
    width: 100% !important;
  }

  .dashboard .bottom-cards .single-card .custom-tooltip-ico {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 999;
  }
}

@media (max-width: 600px) {
  .dashboard .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }

  .dashboard .parent-twice-custom {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .dashboard .dropdown {
    max-width: 100%;
  }

  .dashboard .dropdown .dropdown-toggle {
    width: 100% !important;
  }

  .dashboard .main-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  .dashboard .main-heading h6 {
    font-size: 22px;
  }

  .dashboard .main-heading .btn-influencer {
    width: 100%;
  }
}

.copy-button-add {
  filter: invert(77%) sepia(77%) saturate(4127%) hue-rotate(68deg) brightness(200%) contrast(98%);
  cursor: pointer;
}

.wwwwwwwwwwwwwww {
  font-size: 20px;
}

.dollarimg {
  position: absolute;
  top: 16px;
  left: 7px;
}


