@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&family=Space+Mono:wght@400;700&display=swap");
@import 'react-date-range/dist/styles.css'; 
@import 'react-date-range/dist/theme/default.css'; 

html {
  scroll-behavior: smooth;
}

body {
  background: #000;
  height: 100vh;
  font-family: "Space Mono", monospace;
}

a {
  &:hover {
    background-color: transparent !important;
  }
}

input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

.grey {
  color: #888 !important;
}

.ptb20 {
  padding: 20px 0;
}

.ptb60 {
  padding: 60px 0;
}

.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}

iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

@media (max-width: 600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;
}

button.close {
  opacity: 1;
}

// input styling scss here..........................................

.material-textfield {
  position: relative;
  width: 100%;
  margin-bottom: 35px;
}

.percentageicon {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 117%;
  position: absolute;
  top: 18px;
  right: 18px;
  background: #000;
}

.material-textfield label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  padding: 0 6.4rem 0 1.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  color: #343434;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 90%;
}

.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  border: 1px solid #fff;
  background: #000;
  border-radius: 0px;
  padding: 17px 18px;
  width: 100%;
  box-shadow: none !important;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 117%;

  &::placeholder {
    color: #343434;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 117%;
  }
}

.material-textfield input:focus+label {
  color: #fff;
  top: 0;
  left: 6px;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: unset;
}

.material-textfield input:not(:placeholder-shown)+label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: unset;
}

// custom checkbox scss here........................................

.custom-checkbox {
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  a {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: #fff;
    border: 2px solid transparent;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.btn-login {
  border: 1px solid #fff;
  background: #000;
  box-shadow: 4px 4px 0px 0px #fff;
  padding: 15px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 16px */
  text-transform: uppercase;
}

.custom-container {
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.logout-btn {
  border: 1px solid #fe0000;
  background: #000;
  padding: 13px 35px;
  color: #fe0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 14px */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  max-width: 159px;
  width: 100%;
}

.add-leader {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 16px;
  margin-right: 14px;
  max-width: 170px;
  width: 100%;
}

// custom-sidebar scss here....................................
.custom-sidebar {
  position: absolute;
  top: 70px;
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  background: #000;
  padding: 20px 0;
  max-width: 245px;
  width: 100%;
  height: calc(100% - 70px);
  z-index: 9999;

  ul {
    li {
      a {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        color: #fff !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;

        /* 14px */
        &.active {
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
}

.global-modal-scss {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(5px);

  .modal-content {
    border: 1px solid #fff;
    background: #000;
    box-shadow: 4px 4px 0px 0px #fff;
    padding: 20px;
    border-radius: 0px;
  }

  .modal-header {
    border: none;
    border: 1px solid #fff;
    background: #000;
    border-radius: 0;
    padding: 15px;
    margin-bottom: 40px;

    .modal-title {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 25.2px */
    }

    .btn-close {
      background: url("../src/assets/close-circle.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 1;
    }
  }

  .modal-body {
    padding: 0;

    .delimg {
      width: 80px;
      height: 80px;
      margin: 0 auto 18px;
    }

    .delhead {
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 12px;
    }

    .delpara {
      color: #FFF;
      margin-bottom: 45px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }

    .btn-del {
      display: flex;
      padding: 14px 81px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #FE0000;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .dropdown {
      .dropdown-toggle {
        border: 1px solid #FFF;
        background: #000;
        display: flex;
        padding: 17px 18px;
        justify-content: space-between;
        align-items: center;
        border-radius: 0px;
        width: 100%;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 117%;
        /* 16.38px */
        margin-bottom: 15px;

        .mainspan {
          display: flex;
          padding: 6px 10px;
          align-items: center;
          background: #FFF;
          gap: 7px;

          .spanpara {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
          }
        }
      }

      .dropdown-menu {
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        width: 100%;
        padding: 0px;
        border-radius: 0px;
        max-height: 300px;
        overflow-y: scroll;

        .dropdown-item {
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 12px 15px;
          border-bottom: 1px solid #F3F3F3;
          background: #000;
        }
      }
    }

    .modalhead {
      color: #FFF;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      margin-bottom: 25px;
    }

    .modalpara {
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px;
      margin-bottom: 40px;
      position: relative;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .twice-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      flex: 0 0 48%;
    }

    .btn-cancel {
      background: #050505;
      padding: 14px;
      width: 100%;
      border: none;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .btn-del {
      background: #fe0000;
      border: none;
      padding: 14px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .btn-add {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 14px;
      width: 100%;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 14px */
      text-transform: uppercase;
    }
  }
}

@media (max-width: 991px) {
  .custom-sidebar {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    max-width: 100%;
    z-index: 999999;
    padding: 0px;
  }

  .topdiv {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px;
    border-bottom: 1px solid #FFF;
    background: #000;
    backdrop-filter: blur(12px);
    margin-bottom: 23px;
  }

  .custom-sidebar-top-menu {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding: 20px 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-back {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 14px */
}

.guvxgyvxvtxx {
  margin-bottom: 40px;
}

// .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }

// .footer-content .right-f .page-link:focus, .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }

// .footer-content .right-f .page-link:focus, .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }

// .footer-content .right-f .page-link:focus, .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }

// .datatable .table-responsive .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }
.asdhgadjasfhjaskf {
  max-width: 77px;
  // text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.sdadasdhtdhfhadsgaegegag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.asdasdasdasd {
  padding-left: 6px !important;
}

.shgdvyufgqwiufaksufhasig {
  flex-wrap: wrap !important;
}

// .rs-picker-toggle .rs-picker-toggle-placeholder{
//   display: none !important;
// }

.custom-option-field {
  position: relative;

  input {
    border: 1px solid #fff;
    background: #000;
    width: 100%;
    padding: 15px;
    padding-right: 40px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    height: 46px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .search-icon {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .search-icon-btn {
    position: absolute;
    top: 9px;
    right: 8px;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    text-transform: uppercase;
    padding: 9px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    box-shadow: none !important;
    // box-shadow: 4px 4px 0px 0px #FFF;
  }
}

// custom checkbox style here...........................

// .custom-checkbox {
//   display: block;
// }

// .custom-checkbox input {
//   padding: 0;
//   height: initial;
//   width: initial;
//   margin-bottom: 0;
//   display: none;
//   cursor: pointer;
// }

// .custom-checkbox label {
//   position: relative;
//   cursor: pointer;
// }

// .custom-checkbox label:before {
//   content: "";
//   -webkit-appearance: none;
//   background-color: transparent;
//   border: 2px solid #2e2e2e;
//   padding: 10px;
//   display: inline-block;
//   position: relative;
//   vertical-align: middle;
//   cursor: pointer;
//   margin-right: 5px;
// }

// .custom-checkbox input:checked+label:before {
//   border: 2px solid #fff;
// }

// .custom-checkbox input:checked+label:after {
//   content: "";
//   display: block;
//   position: absolute;
//   top: 5px;
//   left: 9px;
//   width: 6px;
//   height: 14px;
//   border: solid #fff;
//   border-width: 0 2px 2px 0;
//   transform: rotate(45deg);
// }

.common-text-allowed {
  color: #28e664 !important;
}

.common-text-pending {
  color: #ff8300 !important;
}

.reset-btn-global {
  border: 1px solid #fff;
  background: #000;
  padding: 16px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  max-width: 100px;
  width: 100%;
  box-shadow: none !important;
  padding: 16px !important;
  height: 46px;
}

.parent-twice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  max-width: 574px;
  width: 100%;

  .custom-option-field {
    width: 100%;
  }
}

.se-custom-flex-gap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

@media (max-width: 991px) {
  .reset-btn-global {
    max-width: 100%;
  }

  .parent-twice {
    flex-direction: column;
    max-width: 100%;
  }

  .displayblockinmobile {
    display: block !important;
  }
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.parent-twice-custom {
  .set-custom-calendar-div {
    position: absolute;
    top: 0;
    // left: 0;
    left: -77px;
  }
}

@media (max-width: 600px) {
  .parent-twice-custom .set-custom-calendar-div {
    left: 0;
  }
}

.set-custom-calendar-div {
  position: absolute;
  top: 0;
  left: 0;
}

.option-field .calendar-icon {
  position: absolute;
  top: 56px !important;
  right: 20px;
  cursor: pointer;
  pointer-events: none;
}

/* Remove this container when use*/
.component-title {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #888;
  text-align: center;
}

.parent-toggle-switch-custom {
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* The switch - the box around the slider */
  .custom-toggle {
    width: 51px;
    height: 31px;
    position: relative;
  }

  /* Hide default HTML checkbox */
  .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  .redddd {
    background: #FE0000 !important;
  }

  .switch {
    width: 100%;
    height: 100%;
    display: block;
    background: #FE0000;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.2s ease-out;
  }

  /* The slider */
  .slider {
    width: 27px;
    height: 27px;
    position: absolute;
    left: calc(50% - 27px / 2 - 10px);
    top: calc(50% - 27px / 2);
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease-out;
    cursor: pointer;
  }

  .checkbox:checked+.switch {

    background: #28E664;
  }

  .checkbox:checked+.switch .slider {
    left: calc(50% - 27px / 2 + 10px);
    top: calc(50% - 27px / 2);
  }
}

.multiselect-custom {
  margin-bottom: 40px;
  position: relative;

  .custom-label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #000;
    padding: 5px;
    transform: translate(15px, -15px);
    pointer-events: none;
  }

  .searchWrapper {
    border: 1px solid #fff;
    background: #000;
    border-radius: 0;
    min-height: 60px;
    padding: 15px 18px;
  }

  .multiSelectContainer input {
    margin-top: 0;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 117%;

    &::placeholder {
      color: #343434;
    }
  }

  .chip {
    background: #fff;
    border-radius: 0;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    gap: 5px;
  }

  .optionListContainer {
    border: 1px solid #fff;
    background: #000;
    box-shadow: 4px 4px 0px 0px #fff;
  }

  .multiSelectContainer ul {
    border-radius: 0;
    border: none;
  }

  .multiSelectContainer li {
    border-bottom: 1px solid #f3f3f3;
    background-color: transparent;
    padding: 12px 15px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .highlightOption {
    background: transparent !important;

    &:hover {
      background: transparent !important;
    }
  }

  .multiSelectContainer li:hover {
    background: transparent !important;
  }
}

.institute-accord {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;

  .accordion-item {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;

    .accordion-button {
      background-color: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;

      a {
        width: 100%;
      }

      &::after {
        display: none;
      }

      &[aria-expanded="true"] {
        a {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .accordion-body {
    padding: 0;
    padding-left: 30px;
  }
}

.modal-width {
  .modal-dialog {
    max-width: 750px;
    width: 100%;
  }
}

@media (max-width: 750px) {
  .modal-width {
    .modal-dialog {
      .twice-inputssss {
        width: 100%;
        justify-content: flex-start;

        .option-field {
          width: 100%;
          flex: unset;
        }
      }
    }
  }
}


.checkboxmain {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .checkboxpara {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p.checkboxparafst {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.1px;
    color: #ffffff;
    padding-left: 10px;
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  }

  .checkbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox .checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    border: 1.5px solid #FFF;
    transition: all 0.2s ease;
  }

  .checkbox input:checked~.checkmark {
    background: var(--Primary-Neon-Green, #FFF);
    border: 1.5px solid transparent;
  }

  .checkbox .checkmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 5.5px;
    height: 12px;
    border: solid #1c1c1c;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .checkbox input:checked~.checkmark::after {
    opacity: 1;
    transition: all 0.2s ease;
  }
}

@media (max-width:600px) {
  .detailbtn {
    width: 100% !important;
  }

  .global-modal-scss .modal-body .modalhead {
    font-size: 18px;
  }

  .global-modal-scss .modal-body .modalpara {
    font-size: 12px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .dsuygfdsyufgsdyuf {
    margin-bottom: 15px;
  }

  .mobiledesignss {
    display: block !important;
  }

  .footer-content {
    border: 1px solid #FFF;
    background: #000;
    backdrop-filter: blur(3px);
  }
}

.view-btn {
  display: flex;
  padding: 13px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: transparent;
  border: 1px solid #FFF;
  margin-bottom: 20px;
}

.nav-btn {
  display: flex;
  padding: 16px;
  width: 205px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: transparent;
  border: 1px solid #FFF;
  margin-right: 14px;
  background: #000;
  box-shadow: 2px 2px 0px 0px #FFF;
}

.yellow {
  color: #28E664 !important;
}

.red {
  color: #FE0000 !important;
}

.btn-backing {
  display: flex;
  padding: 13px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: transparent;
  border: 1px solid #FFF;
  margin-bottom: 30px;
  background: #000;
  box-shadow: 4px 4px 0px 0px #FFF;
}

.apexcharts-toolbar {
  display: none !important;
}


.multiselect-root {
  position: relative;
  margin-bottom: 40px;

  .searchWrapper {
    border: 1px solid #FFF;
    border-radius: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;

    .chip {
      background: #FFF;
      display: flex;
      padding: 6px 10px;
      align-items: center;
      gap: 7px;
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      max-width: fit-content;
      border-radius: 0px;
      margin: 10px;
      // margin-left: 10px;
    }

    .searchBox {
      padding: 17px 18px;
      background: #000;
      width: 100%;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 117%;

      &::placeholder {
        color: #343434;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 117%;
        /* 16.38px */
      }
    }
  }

  .optionContainer {
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 4px 4px 0px 0px #FFF;
    height: 180px;
    border-radius: 0px;

    &::-webkit-scrollbar {
      display: none !important;
    }

    .option {
      border-bottom: 1px solid #F3F3F3;
      padding: 12px 15px;
      background: #000;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media (max-width:600px) {
  .nav-btn {
    display: block !important;
    width: 90% !important;
    margin: 0 auto;
    max-width: 100% !important;
    margin-top: 20px !important;
  }
}

.searchdropdown {
  max-width: 235px;
  width: 100%;

  .btn {
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 2px 2px 0px 0px #FFF;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    display: flex;
    padding: 15px;
    align-items: center;
    gap: 14px;
    border-radius: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: #000;
    }
  }

  .dropdown-menu {
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 4px 4px 0px 0px #FFF;
    border-radius: 0px;
    width: 100%;
    padding: 0px;
    margin: 0px;

    .searchinput {
      position: relative;

      .searchimg {
        position: absolute;
        top: 12px;
        right: 15px;
      }

      input {
        border-bottom: 1px solid #F3F3F3;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;
        background: transparent;
        width: 100%;
        padding: 12px 15px;

        &::placeholder {
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .searchitem {
      display: flex;
      padding: 12px 15px;
      align-items: center;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-bottom: 1px solid #F3F3F3;
      background: #000;
    }
  }
}

.text-danger {
  position: absolute;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.28px;
}

.border-none {
  border: none !important;
}

.detailbtn {
  border: 1px solid #FFF;
  background: #000;
  box-shadow: 2px 2px 0px 0px #FFF;
  display: flex;
  width: 205px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-left: auto;
  margin-bottom: 20px;
}

.absolutecenter {
  position: absolute;
  bottom: 20px;
  transform: translate(-50%, -50%);
  left: 50%;
  right: 50%;
  max-width: 205px;
}

.nav-pills {
  padding: 0px;
  border: 1px solid #FFF;
  max-width: fit-content;
  margin-bottom: 20px;

  .nav-item {
    border-radius: 0px !important;

    .nav-link {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px 30px;
      border-radius: 0px !important;
      white-space: nowrap;
    }
  }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: #FFF !important;
  color: #000 !important;
}

.checkboxmain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 20px;

  .clear {
    clear: both;
  }

  .checkBox {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0);
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #fff;
  }

  .checkBox div {
    width: 60px;
    height: 60px;
    background-color: #fff;
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }

  .checkBox input[type=checkbox]:checked+div {
    left: -10px;
    top: -10px;
  }

  .checkBox input[type=checkbox] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }

  .transition {
    transition: 300ms ease;
  }

  .checkboxpara {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%;
  }
}

.editpara {
  color: #FFF;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 110%;
}

.okaybtn {
  border: 1px solid #FFF;
  background: #000;
  box-shadow: 4px 4px 0px 0px #FFF;
  display: flex;
  padding: 15px 40px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  width: 100%;
  text-transform: uppercase;
}

.groupstatssss {
  grid-template-columns: 1fr 1fr 1fr !important;
}

@media (max-width:600px){
.groupstatssss{
  grid-template-columns: 1fr 1fr !important;
}
.groupdetailpillsss{
  .nav-item{
    width: 50% !important;
  }
}
.dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-texted .centertext{
flex-direction: column;
}
.dashboard .footer-contenttt .left-f{
  display: none;
}
.dashboard .footer-contenttt .right-f{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
.dashboard .footer-contenttt{
  justify-content: center;
}
.dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-text .centertext{
  font-size: 10px !important;
  text-align: center !important;
}
.dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-text p{
  font-size: 10px !important;
}

.dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-texted p{
  font-size: 14px !important;
  span{
    font-size: 12px !important;
  }
}
.dashboard .chartsmain .innerchart .mainbigchart .new-accordionchart .upper-body .inner-texted h6{
  font-size: 14px !important;
}
}


.set-custom-calendar-div {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 600px) {
  .set-custom-calendar-div {
    position: absolute;
    top: 0;
    left: -70px;
  }
}
