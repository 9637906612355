.main-navbar{
    border-bottom: 1px solid #FFF;
    background: #000;
    backdrop-filter: blur(12px);
    .twice-icons{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
    }
    .navbar{
    padding: 12px 0px;
    }
    .navbar-toggler{
    padding: 0;
    margin: 0;
    }
}

@media (max-width:991px){
    .d-noneonmobile{
        display: none;
    }
    .navbar-collapse{
        display: none;
    }
    .logout-btn{
        display: block !important;
        width: 90% !important;
        margin: 0 auto;
        max-width: 100% !important;
        margin-top: 20px !important;
    }
    // .custom-sidebar{
    //     position: relative;
    //     z-index: 99999999;
    // }
}




.connectwallet-modal {
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(4px);
    z-index: 99999999999;

    .modal-dialog {
        max-width: 500px;
    }

    .modal-content {
        border-radius: 0px;
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        padding: 20px;
    }

    .modal-header {
        border-radius: 0px;
        border: 1px solid #FFF;
        background: #000;
        padding: 15px;
        justify-content: space-between;

        .modal-title {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
        }

        .btn-close {
            background: url("../../../assets/close-circle.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            border: none;
            box-shadow: none;
            padding: 0;
            outline: none;
            opacity: unset;
            width: 24px;
            height: 24px;
            margin: 0;
        }
    }

    .modal-body {
        padding: 0px;

        .darktext {
            background: #080808;
            padding: 20px 10px 10px 20px;
            margin-top: 15px;
            margin-bottom: 30px;

            .scrolltext {
                height: 380px;
                overflow: auto;
                padding-right: 5px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #2B2A2A;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #FFF;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #FFF;
                }

                .formarginleft{
                    margin-left: 30px;
                }

                .darkpara {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 117%;
                    margin-bottom: 14px;
                    .bold{
                        font-weight: 700;
                    }
                    .headingtext{
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 117%;
                    }
                    .formarginleft{
                        margin-left: 30px;
                    }
                }

                .darkparaupper {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 117%;
                    margin: 14px 0px;
                }
            }
        }

        .checkboxmain {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 15px;
            .clear {
                clear: both;
              }
              
              .checkBox {
                display: block;
                cursor: pointer;
                width: 20px;
                height: 20px;
                border: 3px solid rgba(255, 255, 255, 0);
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 0px 2px #FFF;
              }
              
              .checkBox div {
                width: 60px;
                height: 60px;
                background-color: #FFF;
                top: -52px;
                left: -52px;
                position: absolute;
                transform: rotateZ(45deg);
                z-index: 100;
              }
              
              .checkBox input[type=checkbox]:checked + div {
                left: -10px;
                top: -10px;
              }
              
              .checkBox input[type=checkbox] {
                position: absolute;
                left: 50px;
                visibility: hidden;
              }
              
              .transition {
                transition: 300ms ease;
              }

            .checkboxpara {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 117%;
            }
        }

        .continuebutton {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 4px 4px 0px 0px #FFF;
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            margin-top: 25px;
        }

        .disablebutton {
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            color: #363636;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
            border: none;
            margin-top: 25px;
        }

        .twice-btns {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            gap: 15px;

            button {
                background-color: transparent;
                border: 1px solid #242424;
                background: #000;
                height: 130px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition: 0.3s linear;
                gap: 13px;
                padding: 40px;

                h6 {
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                &:hover {
                    border: 1px solid #FFF;
                    background: #000;
                    box-shadow: 2px 2px 0px 0px #FFF;
                }
            }
        }

        .buy-modal {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-top: 30px;

            p {
                color: #FFF;
                font-family: Space Mono;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                text-align: center;
                margin: 20px 0px 30px;

                span {
                    font-weight: 700;
                }
            }

            .btn-common {
                border: 1px solid #FFF;
                background: #000;
                box-shadow: 4px 4px 0px 0px #FFF;
                display: flex;
                padding: 19px 40px;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
                width: 100%;
            }

        }
    }
}


@media (max-width:991px){
    .connectwallet-modal .modal-body .twice-btns {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .connectwallet-modal .modal-body .twice-btns button {
        padding: 30px;
    }

    .connectwallet-modal .modal-body {
        padding: 12px 0;
    }
}



.connect-btn {
    background-color: transparent;
    border: 1px solid #FFF;
    color: #fff;
    padding: 13px 16px;
    margin-right: 14px;
    max-width: 159px;
    width: 100%;
}

.dissconnect-btn {
    border: 1px solid #FE0000;
    background: #000;
    padding: 16px 35px;
    color: #FE0000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    max-width: 159px;
    width: 100%;
}

.dvygdyvdv{
    margin-right: 15px;
}

.mobile-only-set{
   display: none;
}

@media (max-width:991px){
    .mobile-only-set{
        margin: 0 auto;
        display: block;
        width: 100%;
        text-align: center;
        .connect-btn{
            max-width: 90%;
            width: 100%;
            margin: 0 auto;
            margin-top: 50px !important;
        }
        .dissconnect-btn{
            max-width: 90%;
            width: 100%;
            margin: 0 auto;
            margin-top: 50px !important;
        }
    }
}

.special-access{
    margin: 40px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    h6{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}