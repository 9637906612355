.main-user {
    padding-top: 50px;

    .parentbox {
        display: flex;
        // align-items: center;
        gap: 10px;

        .mainusercard {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 3px 3px 0px 0px #FFF;
            padding: 25px;
            width: 100%;

            .mainheading {

                p {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }

                h4 {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: -0.22px;
                }
            }
        }
    }

    .main-heading {
        .searchinputt {
            position: relative;
            min-width: 345px;
            width: 30%;

            .searchimg {
                position: absolute;
                top: 15px;
                right: 15px;
            }

            input {
                border: 1px solid #FFF;
                background: #000;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                outline: none;
                background: transparent;
                width: 100%;
                padding: 12px 15px;
                outline: none;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.60);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                }
            }
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0px;

        h6 {
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }

        button {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 2px 2px 0px 0px #FFF;
            padding: 15px;
            max-width: 205px;
            width: 100%;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }
    }
}

@media(max-width:600px) {
    .mobilecard {
        display: block !important;

        .mblparent {
            display: flex;

            justify-content: flex-start;
            gap: 10px;
            margin-top: 10px;
            margin-bottom: 30px;

            .mainusercard {
                border: 1px solid #FFF;
                background: #000;
                box-shadow: 3px 3px 0px 0px #FFF;
                padding: 25px;
                max-width: 190px;
                width: 100%;

                .mainheading {

                    p {
                        color: #FFF;
                        margin-top: 20px;
                        margin-bottom: 10px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }

                    h4 {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        /* 19.2px */
                        letter-spacing: -0.16px;
                    }
                }
            }
        }

    }

    .formbl {
        display: none;
    }

    .main-heading {
        flex-direction: column;
        align-items: start !important;
    }

    .main-user .main-heading button {
        width: 100% !important;
        margin-top: 30px;
        max-width: unset;
        margin-bottom: 20px;
    }
}