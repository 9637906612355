.forgotpassword-wrapper{
    .navbar-expand-lg .navbar-collapse{
        display: none !important;
    }
    .d-noneonmobile{
        display: none !important;
    }
}

.forgot-password {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .parent-card {
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        max-width: 542px;
        width: 100%;
        padding: 35px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;

        .upper-logo {
            text-align: center;
            margin-bottom: 50px;
        }

        .newcontentt{
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            gap: 28px;
        }
        .bottom-content {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;

            h6 {
                margin-bottom: 15px;
                color: #FFF;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                /* 24.2px */
                text-transform: uppercase;
            }

            p {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                /* 18.2px */
                margin-bottom: 40px;
            }

            .option-field {
                width: 100%;
            }

            .btn-send {
                border: 1px solid #FFF;
                background: #000;
                box-shadow: 4px 4px 0px 0px #FFF;
                padding: 15px;
                width: 100%;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 16px */
                text-transform: uppercase;
            }
        }
    }
    .btn-back{
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: rgba(255, 255, 255, 0.05);
        padding: 15px 10px;
        display: flex;
        align-items: center;
        gap: 6px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .option-field{
        width: 100%;
        position: relative;
        .password-icon{
            position: absolute;
            top: 13px;
            right: 16px;
        }
        .iferrorinput{
            border: 1px solid #FE0000 !important;
            color: #FE0000 !important;
        }
        .iferrorinputtext{
            color: #FE0000 !important;
        }
    }
    .listing-item{
        width: 100%;
        margin-bottom: 40px;
        h6{
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 6px;
        }
        ul{
            li{
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 5px;
                list-style-type: disc;
                list-style-position: inside;
            }
        }
    }
}

@media (max-width:600px){
    .forgot-password .parent-card{
        padding: 30px 20px;
    }
}